import * as React from "react";

import Cart from "../components/Cart/Cart"
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const CartPage = () => {
  return (
    <Layout>
        <Cart />
    </Layout>
  );
}

export default CartPage

export const Head = () => (
  <SEO title="AceMedical Wholesale | Cart" description="Your shopping cart for wholesale medical product here." />
)