import * as React from 'react'

import { CloseButton, Flex, Link, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, SelectProps, Text, useColorModeValue } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { CartProductMeta } from './CartProductMeta'

type CartItemProps = {
  isGiftWrapping?: boolean
  name?: string
  quantity: number
  price: string
  imageUrl?: string
  productTags: any
  databaseId: any
  key: string
  onChangeQuantity: (qty: number) => void
  onClickGiftWrapping?: () => void
  onClickDelete?: (key:any) => void
}

const QuantitySelect = (props: SelectProps) => {

  function print() {
    let items = [];      
    for(var i= 0; i<=50; i++){
      items.push(<option key={i} value={i}>{i}</option>);   
    } 
    return items;
  }
    
  return (
    <Select
      maxW="64px"
      aria-label="Select quantity"
      focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
      {...props}
      id="qty_select"
    >
      {print()}

    </Select>
  )
}

export const CartItem = (props: CartItemProps) => {
  
  const {
    name,
    quantity,
    imageUrl,
    price,
    key,
    productTags,
    onChangeQuantity,
    onClickDelete,
  } = props

  var priceSub = price.slice(0, - 3);
  
  return (
    <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
      <CartProductMeta
        quantity={quantity}
        name={name}
        image={imageUrl}
        productTags={productTags}
      />

      {/* Desktop */}
      <Flex width="full" justify="space-between" alignItems="center" display={{ base: 'none', md: 'flex' }}>
        {/* <QuantitySelect
          value={quantity}
          onChange={(e) => {
            onChangeQuantity(parseInt(e.currentTarget.value));
          }}
        /> */}

          <NumberInput
            min={1}
            defaultValue={quantity}
            onBlur={(e) => { onChangeQuantity(parseInt(e.currentTarget.value)) }}
            width="20%"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

        <Text fontWeight="bold"> { priceSub }</Text>
        <CloseButton aria-label={`Delete ${name} from cart`} onClick={onClickDelete} />

      </Flex>

      {/* Mobile */}
      <Flex
        mt="4"
        align="center"
        width="full"
        justify="space-between"
        display={{ base: 'flex', md: 'none' }}
      >
        <Link fontSize="sm" textDecor="underline" onClick={onClickDelete}>
          Delete
        </Link>
        <Text fontWeight="bold"> { priceSub }</Text>
        {/* <QuantitySelect
          value={quantity}
          onChange={(e) => {
            onChangeQuantity(parseInt(e.currentTarget.value));
          }}
        /> */}
         <NumberInput
            min={1}
            defaultValue={quantity}
            onBlur={(e) => { onChangeQuantity(parseInt(e.currentTarget.value)) }}
            width="20%"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
      </Flex>
    </Flex>
  )
}