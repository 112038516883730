import { Box, Button, Container, Heading, Link, Text } from '@chakra-ui/react';

import React from 'react'

const EmptyCart = () => {

    return (
        <Box bg="bg-surface" py="28">
        {/* <SvgBackground></SvgBackground> */}
        <Container maxW="800px" px="4" py={{ base: '4', md: '8' }} >
            <Box display="flex" justifyContent="center" bg="white" py="14" px='2' textAlign="center" borderRadius="lg" boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);" >
                <Box w={{base: '100%', md: "480px"}}>
                    <Heading fontSize="xl" > Your cart is empty! </Heading>
                    <Text lineHeight="7" py='4' color="gray.600"> There are no items in your cart at the moment. </Text>
                    <Link href="/shop" _hover={{ textDecoration: "none !important" }} ><Button width="100%">  Start Shopping</Button></Link> 
                </Box>
          </Box>
        </Container>
        </Box>
    )
}

export default EmptyCart